<template>
  <div>
    <b-card header="My Quotations" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>

        <div class="float-right" style="padding-bottom: 10px">
          <router-link :to="{ name: 'customer-quotation-request' }"><button class="btn btn-secondary">Request a Quote</button></router-link>
        </div>

        <div style="padding-top: 10px">
          <table class="table table-responsive-sm table-striped border">
            <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Your Reference</th>
              <th scope="col">Type</th>
              <th scope="col">Origin</th>
              <th scope="col">Destination</th>
              <th scope="col">Incoterm</th>
              <th scope="col">Load</th>
              <th scope="col">Pieces</th>
              <th scope="col">Weight/Chargeable Weight</th>
              <th scope="col">Requested by</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(shipment, index) in shipments" :key="index">
              <td>{{ shipment.shipmentDate }}</td>
              <td>{{ shipment.customerReference }}</td>
              <td>{{ shipment.shipmentType }}</td>
              <td>{{ shipment.origin }}</td>
              <td>{{ shipment.destination }}</td>
              <td>{{ shipment.shipmentIncoterm }}</td>
              <td>{{ shipment.shipmentCargoType }}</td>
              <td>{{ shipment.shipmentPieces }}</td>
              <td>{{ shipment.shipmentWeight }} kg ({{ shipment.shipmentChargeableWeight }} kg)</td>
              <td>{{ shipment.customerRequestedBy }}</td>
              <td>{{ shipment.quoteStatus }}</td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "QuotationsList",

  data(){
    return {
      shipments: [
        {
          id: 1,
          shipmentDate: '01/01/2020',
          shipmentType: 'Airfreight Import',
          origin: 'New York (JFK)',
          destination: 'Frankfurt / Main (FRA)',
          shipmentIncoterm: 'DAP',
          shipmentCargoType: 'LCL',
          shipmentPieces: '1',
          shipmentWeight: '125.00',
          shipmentChargeableWeight: '250.00',
          customerRequestedBy: 'Username',
          customerReference: '20-2002-01',
          quoteStatus: 'Pending'
        },
        {
          id: 2,
          shipmentDate: '02/01/2020',
          shipmentType: 'Airfreight Import',
          origin: 'New York (JFK)',
          destination: 'Frankfurt / Main (FRA)',
          shipmentIncoterm: 'DAP',
          shipmentCargoType: 'LCL',
          shipmentPieces: '1',
          shipmentWeight: '125.00',
          shipmentChargeableWeight: '250.00',
          customerRequestedBy: 'Username',
          customerReference: '20-2002-01',
          quoteStatus: 'Pending'
        },
        {
          id: 3,
          shipmentDate: '02/01/2020',
          shipmentType: 'Airfreight Import',
          origin: 'New York (JFK)',
          destination: 'Frankfurt / Main (FRA)',
          shipmentIncoterm: 'DAP',
          shipmentCargoType: 'LCL',
          shipmentPieces: '1',
          shipmentWeight: '125.00',
          shipmentChargeableWeight: '250.00',
          customerRequestedBy: 'Username',
          customerReference: '20-2002-01',
          quoteStatus: 'Pending'
        },
        {
          id: 4,
          shipmentDate: '02/01/2020',
          shipmentType: 'Airfreight Import',
          origin: 'New York (JFK)',
          destination: 'Frankfurt / Main (FRA)',
          shipmentIncoterm: 'DAP',
          shipmentCargoType: 'LCL',
          shipmentPieces: '1',
          shipmentWeight: '125.00',
          shipmentChargeableWeight: '250.00',
          customerRequestedBy: 'Username',
          customerReference: '20-2002-01',
          quoteStatus: 'Pending'
        },
        {
          id: 5,
          shipmentDate: '02/01/2020',
          shipmentType: 'Airfreight Import',
          origin: 'New York (JFK)',
          destination: 'Frankfurt / Main (FRA)',
          shipmentIncoterm: 'DAP',
          shipmentCargoType: 'LCL',
          shipmentPieces: '1',
          shipmentWeight: '125.00',
          shipmentChargeableWeight: '250.00',
          customerRequestedBy: 'Username',
          customerReference: '20-2002-01',
          quoteStatus: 'Pending'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>