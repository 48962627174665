<template>
  <div>

    <div class="row">
      <div class="col col-12">

        <b-card header="Request new Quote" align="left"  header-text-variant="white" header-bg-variant="secondary">
          <b-card-text>

            <slot v-if="shipmentType === ''">
              <div class="row mb-3">
                <div class="col">
                  <button class="btn btn-lg btn-success" id="selectType" name="selectType" @click="selectType('AIRIMP')">Airfreight Import</button>
                </div>
              </div>
            </slot>





            <slot v-if="shipmentType !== ''">
              <div class="form-group">
                <label for="">Please choose Incoterm:</label>
                <select name="" id="" class="form-control" v-model="shipmentIncoterm">
                  <option value="" disabled>- Please choose Incoterm</option>
                  <option value="CPT">CPT</option>
                  <option value="CIF">CIF</option>
                  <option value="DAP">DAP</option>
                  <option value="DPU">DPU</option>
                  <option value="DDP">DDP</option>
                </select>
              </div>

              <hr>

              <div class="alert alert-success" role="alert" v-if="shipmentIncoterm === 'CPT' || shipmentIncoterm === 'CIF'">
                <p>Do you have freehand-shipment? Please contact us! In case of freehand-shipment the D/O charges will be billed to consignee or it's forwarder.</p>
                <button type="button" class="btn btn-success">Add your freehand-shipment & get 10,00 EUR incentive</button>
              </div>

              <div class="form-row" v-if="shipmentIncoterm === 'DAP' || shipmentIncoterm === 'DPU' || shipmentIncoterm === 'DDP'">

                <div class="form-group col-md-12">

                  <label for="DeliveryAddress">Delivery Address</label>
                  <select name="" id="DeliveryAddress" class="form-control" v-model="deliveryZone">
                    <option value="" disabled>- Please choose City of Delivery</option>
                    <option value="zone1">35xxx (Zone 1)</option>
                    <option value="zone1">55xxx (Zone 1)</option>
                    <option value="zone1">60xxx (Zone 1)</option>
                    <option value="zone1">61xxx (Zone 1)</option>
                    <option value="zone1">62xxx (Zone 1)</option>
                    <option value="zone1">63xxx (Zone 1)</option>
                    <option value="zone1">64xxx (Zone 1)</option>
                    <option value="zone1">65xxx (Zone 1)</option>
                  </select>

                </div>



              </div>

            </slot>

            <slot v-if="deliveryZone">

              <hr>

              <h3>Cargo Details</h3>

              <div class="form-row">
                <div class="form-group col-md-1">
                  <label for="" class="small">Pieces</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="" class="small">Length (cm)</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="" class="small">Width (cm)</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="" class="small">Height (cm)</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="" class="small">Weight / Piece (kg)</label>
                </div>
                <div class="form-group col-md-1">
                  <label for="" class="small">Cbm</label>
                </div>
                <div class="form-group col-md-2">
                </div>
              </div>

              <div class="form-row" v-for="(row, index) in cargoDetails" :key="index">
                <div class="form-group col-md-1">
                  <input type="text" class="form-control form-control-sm" v-model.number="row.pieces">
                </div>
                <div class="form-group col-md-2">
                  <input type="text" class="form-control form-control-sm" v-model.number="row.length">
                </div>
                <div class="form-group col-md-2">
                  <input type="text" class="form-control form-control-sm" v-model.number="row.width">
                </div>
                <div class="form-group col-md-2">
                  <input type="text" class="form-control form-control-sm" v-model.number="row.height">
                </div>
                <div class="form-group col-md-2">
                  <input type="text" class="form-control form-control-sm" v-model.number="row.weight">
                </div>
                <div class="form-group col-md-1">
                  <input type="text" class="form-control form-control-sm" readonly v-model.number="subtotalRow[index]">
                </div>
                <div class="form-group col-md-2">
                  <button class="btn btn-secondary btn-sm mr-1 w-25" @click="addRow">+</button>
                  <button class="btn btn-danger btn-sm mr-1 w-25" @click="removeRow(index)" v-if="currentId !== 1">-</button>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-1 d-flex justify-content-center font-weight-bold">
                  {{ totalPieces }} pcs
                </div>
                <div class="form-group col-md-2">
                </div>
                <div class="form-group col-md-2">
                </div>
                <div class="form-group col-md-2">
                  {{ totalChargeableWeight }}
                </div>
                <div class="form-group col-md-2 form-group col-md-1 d-flex justify-content-center font-weight-bold">
                  {{ totalWeight }} kgs
                </div>
                <div class="form-group col-md-1 form-group col-md-1 d-flex justify-content-center font-weight-bold">
                  {{ totalCbm }} cbm
                </div>
                <div class="form-group col-md-2">
                </div>
              </div>

              <hr>

              <h3>Additional Cargo Information</h3>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="">Commodity:</label>
                  <select name="" id="" class="form-control" v-model="shipmentCommodity">
                    <option value="GC">General Cargo</option>
                    <option value="DG">Dangerous Goods</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="">Stackable:</label>
                  <select name="" id="" class="form-control" v-model="shipmentStackable">
                    <option value="Y">Stackable</option>
                    <option value="N">NOT Stackable</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="">Delivery Service:</label>
                  <select name="" id="" class="form-control" v-model="shipmentDeliveryService">
                    <option value="STD">Standard (24-48h)</option>
                    <option value="EXP">Next-Day Service (Money Back Guarantee)</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="">Delivery with Forklift/Gatelift:</label>
                  <select name="" id="" class="form-control" v-model="shipmentDeliveryForklift">
                    <option value="N">No Forklift/Gatelift needed</option>
                    <option value="Y">Forklift/Gatelift needed</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6" v-if="shipmentIncoterm === 'DDP' || shipmentIncoterm === 'DPU'">
                  <label for="">HS Codes:</label>
                  <select name="" id="" class="form-control">
                    <option value="">1</option>
                    <option value="">2</option>
                  </select>
                </div>
              </div>

            </slot>

            <slot v-if="deliveryZone">

              <hr>

              <div class="form-group">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="tocAccepted" name="tocAccepted" v-model="tocAccepted">
                  <label class="form-check-label" for="tocAccepted">
                    I have read Terms & Conditions for Pricing
                  </label>
                </div>
                <slot v-if="tocAccepted">
                  <b-button v-b-modal.modal>Request a Quote</b-button>
                </slot>
              </div>
            </slot>

          </b-card-text>
        </b-card>

        <!--- Modal --->

        <b-modal id="modal" size="xl" title="Our Offer">

          <div class="row">
            <div class="col">Service</div>
            <div class="col">Charge</div>
            <div class="col"></div>
          </div>

          <div class="row">
            <div class="col">Atlas Fee</div>
            <div class="col"></div>
          </div>

          <div class="row">
            <div class="col">Handling</div>
            <div class="col">35.00 €</div>
          </div>

          <div class="row">
            <div class="col">Customs Clearance</div>
            <div class="col">35.00 €</div>
          </div>

          <div class="row">
            <div class="col">Additional HS Codes:</div>
            <div class="col">35.00 €</div>
          </div>

          <div class="row">
            <div class="col">THC</div>
            <div class="col">35.00 €</div>
          </div>

          <div class="row">
            <div class="col">Delivery Charge</div>
            <div class="col">35.00 €</div>
          </div>
          <div class="row">
            <div class="col">Total Charges</div>
            <div class="col"></div>
          </div>


        </b-modal>

      </div>

    </div>


  </div>
</template>

<script>
let numeral = require('numeral');

export default {
  name: "QuotationRequest",

  data() {
    return {

      shipmentType: '',
      shipmentIncoterm: '',
      airportOrigin: '',
      airportDestination: '',

      tocAccepted: null,

      currentId: 1,
      cargoDetails: [
          {
            id: 1,
            pieces: '',
            length: '',
            width: '',
            height: '',
            weight: ''
          }
      ],

      shipmentCommodity: 'GC',
      shipmentStackable: 'Y',
      shipmentDeliveryService: 'STD',
      shipmentDeliveryForklift: 'N',
      shipmentHSCodes: '',

      deliveryZone: '',

      selectedItems: [
        {
          name: 'Delivery',
          amount: 0,
          currency: 'EUR'
        }
      ],

      chargesItems: [
        {
          name: 'Atlas',
          amount: '0',
          currency: 'EUR'
        }
      ]

    }
  },
  methods: {
    selectType(type){
      this.shipmentType = type
    },
    addRow: function (){

      this.currentId++

      this.cargoDetails.push(
          {
            id: this.currentId,
            pieces: '',
            length: '',
            width: '',
            height: '',
            weight: ''
          }
      );
    },
    removeRow: function(row){
      this.cargoDetails.splice(row, 1)

      this.currentId--
    },
  },
  computed: {
    subtotalRow() {
      return this.cargoDetails.map((item) => {
        return numeral(item.pieces * (item.length/100) * (item.width/100) * (item.height/100)).format('0.00');
      });
    },
    totalPieces() {
      return this.cargoDetails.reduce((total, item) => {
        return total + item.pieces;
      }, 0);
    },
    totalWeight() {
      return this.cargoDetails.reduce((total, item) => {
        return total + (item.pieces * item.weight);
      }, 0);
    },
    totalCbm() {
      return this.cargoDetails.reduce((total, item) => {
        return total + (item.pieces * (item.length/100) * (item.width/100) * (item.height/100));
      }, 0);
    }
  }
}
</script>

<style scoped>

</style>