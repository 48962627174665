<template>
  <div>
    <b-card header="Create new Shipment" align="left"  header-text-variant="white" header-bg-variant="dark">
      <b-card-text>

        <!-- Step 1 -->
        <slot id="step1" v-if="wizardCurrentStep === 1">

          <!-- Type of Shipment -->
          <slot>
            <div class="form-row mb-3">
              <label for="selectShipmentType">Please choose Type of Shipment</label>
              <select name="selectShipmentType" id="selectShipmentType" class="form-control" v-model="shipmentType">
                <option value="" disabled>Please choose Typ of Shipment</option>
                <option value="AIRIMP">Airfreight Import</option>
              </select>
            </div>
          </slot>

          <!-- AWB Information -->
          <slot>
            <div class="row mb-3">
              <div class="col-6">
                <div class="form-group">
                  <label for="" class="font-weight-bold">MAWB</label>
                  <div class="form-row">
                    <div class="form-group col-md-2">
                      <input type="text" placeholder="Prefix" class="form-control" maxlength="3" v-model="shipmentMawbPrefix">
                    </div>
                    <div class="form-group col-md-10">
                      <input type="text" placeholder="MAWB No" class="form-control" maxlength="8" v-model="shipmentMawbNumber">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="checkIataDirect">
                    <label class="custom-control-label" for="customSwitch1">IATA Direct Only</label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" v-if="checkIataDirect === false">
                  <label for="" class="font-weight-bold">HAWB</label>
                  <div class="form-row">
                    <div class="form-group col-md-2">
                      <input type="text" placeholder="Prefix" class="form-control" maxlength="3">
                    </div>
                    <div class="form-group col-md-10">
                      <input type="text" placeholder="MAWB No" class="form-control" maxlength="8">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slot>

          <!-- Incoterm -->
          <slot>
            <div class="form-row mb-3">
              <label for="selectIncoterm">Please choose Incoterm of Shipment</label>
              <select name="selectShipmentType" id="selectIncoterm" class="form-control" v-model="shipmentIncoterm">
                <option value="" disabled>Please choose Incoterm of Shipment</option>
                <option value="DAP">DAP</option>
              </select>
            </div>
          </slot>

          <hr>

        </slot>

        <!-- Step 2 -->
        <slot id="step2" v-if="wizardCurrentStep === 2">
          <slot id="" v-if="shipmentType">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="">Shipper:</label>
                  <input type="text" class="form-control" placeholder="Company Name" v-model="shipperCompanyName">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Additional Address Information" v-model="shipperAdditionalAddress">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Street Information" v-model="shipperStreet">
                </div>
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <input type="text" placeholder="Zip Code" class="form-control" v-model="shipperZipCode">
                  </div>
                  <div class="form-group col-md-7">
                    <input type="text" placeholder="City" class="form-control" v-model="shipperCity">
                  </div>
                  <div class="form-group col-md-3">
                    <input type="text" placeholder="Country" class="form-control" v-model="shipperCountry">
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Contact at Shipper</label>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <input type="text" placeholder="Full Name" class="form-control">
                    </div>
                    <div class="form-group col-md-4">
                      <input type="text" placeholder="Phone No" class="form-control">
                    </div>
                    <div class="form-group col-md-4">
                      <input type="email" placeholder="E-Mail" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Reference No</label>
                  <input type="text" class="form-control" placeholder="Reference No">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Consignee:</label>
                  <input type="text" class="form-control" placeholder="Company Name" v-model="consigneeCompanyName">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Additional Address Information" v-model="consigneeAdditionalAddress">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Street Information" v-model="consigneeStreet">
                </div>
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <input type="text" placeholder="Zip Code" class="form-control" v-model="consigneeZipCode">
                  </div>
                  <div class="form-group col-md-7">
                    <input type="text" placeholder="City" class="form-control" v-model="consigneeCity">
                  </div>
                  <div class="form-group col-md-3">
                    <input type="text" placeholder="Country" class="form-control" v-model="consigneeCountry">
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Contact at Consignee</label>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <input type="text" placeholder="Full Name" class="form-control">
                    </div>
                    <div class="form-group col-md-4">
                      <input type="text" placeholder="Phone No" class="form-control">
                    </div>
                    <div class="form-group col-md-4">
                      <input type="email" placeholder="E-Mail" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Reference No</label>
                  <input type="text" class="form-control" placeholder="Reference No">
                </div>
                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checkNotify">
                    <label class="custom-control-label" for="customSwitch2">Notify ?</label>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <slot v-if="checkNotify === true">
              <div class="row">
                <div class="col-6">

                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="font-weight-bold">Notify:</label>
                    <input type="text" class="form-control" placeholder="Company Name" v-model="notifyCompanyName">
                  </div>

                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Additional Address Information" v-model="notifyAdditionalAddress">
                  </div>

                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Street Information" v-model="notifyStreet">
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-2">
                      <input type="text" placeholder="Zip Code" class="form-control" v-model="notifyZipCode">
                    </div>
                    <div class="form-group col-md-7">
                      <input type="text" placeholder="City" class="form-control" v-model="notifyCity">
                    </div>
                    <div class="form-group col-md-3">
                      <input type="text" placeholder="Country" class="form-control" v-model="notifyCountry">
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">Contact at Consignee</label>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <input type="text" placeholder="Full Name" class="form-control">
                      </div>
                      <div class="form-group col-md-4">
                        <input type="text" placeholder="Phone No" class="form-control">
                      </div>
                      <div class="form-group col-md-4">
                        <input type="email" placeholder="E-Mail" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
            </slot>
          </slot>
        </slot>

        <!-- Step 3 -->
        <slot id="step3" v-if="wizardCurrentStep === 3">
          <slot>
            <div class="row">
              <div class="col-6">
                <div class="form-row">
                  <div class="form-group col-md-1">
                    <label for="">Pieces</label>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="">Length (cm)</label>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="">Width (cm)</label>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="">Height (cm)</label>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="">Weight / Piece (kg)</label>
                  </div>
                  <div class="form-group col-md-1">
                    <label for="">Cbm</label>
                  </div>
                  <div class="form-group col-md-2">
                  </div>
                </div>
                <div class="form-row" v-for="(row, index) in cargoDetails" :key="index">
                  <div class="form-group col-md-1">
                    <input type="text" class="form-control form-control-sm" v-model.number="row.pieces">
                  </div>
                  <div class="form-group col-md-2">
                    <input type="text" class="form-control form-control-sm" v-model.number="row.length">
                  </div>
                  <div class="form-group col-md-2">
                    <input type="text" class="form-control form-control-sm" v-model.number="row.width">
                  </div>
                  <div class="form-group col-md-2">
                    <input type="text" class="form-control form-control-sm" v-model.number="row.height">
                  </div>
                  <div class="form-group col-md-2">
                    <input type="text" class="form-control form-control-sm" v-model.number="row.weight">
                  </div>
                  <div class="form-group col-md-1">
                    <input type="text" class="form-control form-control-sm" readonly v-model.number="subtotalRow[index]">
                  </div>
                  <div class="form-group col-md-2">
                    <button class="btn btn-secondary btn-sm mr-1 w-25" @click="addRow">+</button>
                    <button class="btn btn-danger btn-sm mr-1 w-25" @click="removeRow(index)" v-if="currentId !== 1">-</button>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-1 d-flex justify-content-center font-weight-bold">
                    {{ totalPieces }} pcs
                  </div>
                  <div class="form-group col-md-2">
                  </div>
                  <div class="form-group col-md-2">
                  </div>
                  <div class="form-group col-md-2">
                    {{ totalChargeableWeight }}
                  </div>
                  <div class="form-group col-md-2 form-group col-md-1 d-flex justify-content-center font-weight-bold">
                    {{ totalWeight }} kgs
                  </div>
                  <div class="form-group col-md-1 form-group col-md-1 d-flex justify-content-center font-weight-bold">
                    {{ totalCbm }} cbm
                  </div>
                  <div class="form-group col-md-2">
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="" class="font-weight-bold">Commodity:</label>
                  <input type="text" class="form-control" placeholder="Commodity" >
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="">
                </div>
              </div>
            </div>
            <hr>
          </slot>
        </slot>

        <!-- Step 4 -->
        <slot id="step4" v-if="wizardCurrentStep === 4">
          <slot>
            <div class="row">
              <div class="col-6">
                <div class="form-row mb-2">
                  <label for="">MAWB</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="validatedInputGroupCustomFile1" required>
                      <label class="custom-file-label" for="validatedInputGroupCustomFile1">MAWB: Choose file...</label>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button">Upload</button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <label for="">HAWB</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="validatedInputGroupCustomFile2" required>
                      <label class="custom-file-label" for="validatedInputGroupCustomFile2">MAWB: Choose file...</label>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button">Upload</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-row mb-2">
                  <label for="">Invoice / Proforma</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="validatedInputGroupCustomFile3" required>
                      <label class="custom-file-label" for="validatedInputGroupCustomFile3">Choose file...</label>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button">Upload</button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <label for="">Packing List / Delivery Note</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="validatedInputGroupCustomFile4" required>
                      <label class="custom-file-label" for="validatedInputGroupCustomFile4">Choose file...</label>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button">Upload</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </slot>
        </slot>

        <!-- Wizard -->
        <slot>
          <div class="mb-3">
            <button class="btn btn-secondary float-left" @click="wizardBackStep" :disabled="wizardCurrentStep === 1">Back</button>
            <button class="btn btn-secondary float-right" @click="wizardNextStep">Next</button>
          </div>
        </slot>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
let numeral = require('numeral');

export default {
  name: "ShipmentCreate",

  data(){
    return {
      shipmentType: 'AIRIMP',
      shipmentIncoterm: '',
      shipmentMAWB: '',
      shipmentHAWB: '',

      checkIataDirect: false,
      checkNotify: false,

      shipperCompanyName: '',
      shipperAdditionalAddress: '',
      shipperStreet: '',
      shipperZipCode: '',
      shipperCity: '',
      shipperCountry: '',
      shipperContactName: '',
      shipperPhone: '',
      shipperEmail: '',
      shipperReference: '',

      consigneeCompanyName: '',
      consigneeAdditionalAddress: '',
      consigneeStreet: '',
      consigneeZipCode: '',
      consigneeCity: '',
      consigneeCountry: '',
      consigneeContactName: '',
      consigneePhone: '',
      consigneeEmail: '',
      consigneeReference: '',

      notifyCompanyName: '',
      notifyAdditionalAddress: '',
      notifyStreet: '',
      notifyZipCode: '',
      notifyCity: '',
      notifyCountry: '',
      notifyContactName: '',
      notifyPhone: '',
      notifyEmail: '',

      currentId: 1,
      cargoDetails: [
        {
          id: 1,
          pieces: '',
          length: '',
          width: '',
          height: '',
          weight: ''
        }
      ],

      wizardCurrentStep: 1,

    }
  },
  methods: {
    wizardNextStep(){
      this.wizardCurrentStep++
    },
    wizardBackStep(){
      if(this.wizardCurrentStep > 1){
        this.wizardCurrentStep--
      }
    },
    addRow: function (){

      this.currentId++

      this.cargoDetails.push(
          {
            id: this.currentId,
            pieces: '',
            length: '',
            width: '',
            height: '',
            weight: ''
          }
      );
    },
    removeRow: function(row){
      this.cargoDetails.splice(row, 1)

      this.currentId--
    },
  },
  computed: {
    subtotalRow() {
      return this.cargoDetails.map((item) => {
        return numeral(item.pieces * (item.length/100) * (item.width/100) * (item.height/100)).format('0.00');
      });
    },
    totalPieces() {
      return this.cargoDetails.reduce((total, item) => {
        return total + item.pieces;
      }, 0);
    },
    totalWeight() {
      return this.cargoDetails.reduce((total, item) => {
        return total + (item.pieces * item.weight);
      }, 0);
    },
    totalCbm() {
      return this.cargoDetails.reduce((total, item) => {
        return total + (item.pieces * (item.length/100) * (item.width/100) * (item.height/100));
      }, 0);
    }
  }
}
</script>

<style scoped>

</style>