<template>
  <div>
    <b-card header="Loyalty Program" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>

        <p>Information about Royalty Program</p>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
name: "LoyaltyProgram"
}
</script>

<style scoped>

</style>