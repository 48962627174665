<template>
  <div>
    <b-card header="Invoices" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>
        <div class="float-right" style="padding-bottom: 10px">

        </div>

        <div style="padding-top: 10px">
          <table class="table table-responsive-sm table-striped border">
            <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Invoice No.</th>
              <th scope="col">File No.</th>
              <th scope="col">Your reference</th>
              <th scope="col">Amount</th>
              <th scope="col">Due Date</th>
              <th scope="col">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in invoices" :key="index">
              <td>{{ data.date }}</td>
              <td>{{ data.invoiceNumber }}</td>
              <td>{{ data.fileNumber }}</td>
              <td>{{ data.invoiceReference }}</td>
              <td>{{ data.invoiceAmount }} {{ data.invoiceAmountCurrency }}</td>
              <td>{{ data.invoiceDueDate }}</td>
              <td>{{ data.invoiceStatus }}</td>
              <td>Download</td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "InvoicesList",

  data(){
    return {
      invoices: [
        {
          id: 1,
          date: '20/02/2020',
          invoiceNumber: 'DE-2020-00001',
          fileNumber: '101-2020-0001',
          invoiceReference: '00004545',
          invoiceAmount: '14544.20',
          invoiceAmountCurrency: 'EUR',
          invoiceDueDate: '20/03/2020',
          invoiceStatus: 'Unpaid'
        },
        {
          id: 2,
          date: '20/02/2020',
          invoiceNumber: 'DE-2020-00001',
          fileNumber: '101-2020-0002',
          invoiceReference: '00004545',
          invoiceAmount: '14544.20',
          invoiceAmountCurrency: 'EUR',
          invoiceDueDate: '20/03/2020',
          invoiceStatus: 'Unpaid'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>