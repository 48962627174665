<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" class="logo">
    <login></login>
  </div>
</template>

<script>
import Login from "../components/auth/Login";

export default {
  name: 'Home',
  components: {
    Login
  }
}
</script>
