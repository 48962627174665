<template>
  <div>
    <b-card header="Shipments" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>
        <div class="float-right" style="padding-bottom: 10px">
          <router-link :to="{ name: 'customer-shipments-create' }"><button class="btn btn-secondary">Add new Shipment</button></router-link>
        </div>

        <div style="padding-top: 10px">
          <table class="table table-sm table-striped border">
            <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">File-No</th>
              <th scope="col">Type</th>
              <th scope="col">Customer</th>
              <th scope="col">Shipper</th>
              <th scope="col">Consignee</th>
              <th scope="col">Origin</th>
              <th scope="col">Destination</th>
              <th scope="col">Cargo</th>
              <th scope="col">Pieces</th>
              <th scope="col">Weight</th>
              <th scope="col">Chargeable Weight</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in shipments" :key="index">
              <td>{{ data.date }}</td>
              <td>{{ data.fileNumber }}</td>
              <td>{{ data.shipmentType }}</td>
              <td>{{ data.customer }}</td>
              <td>{{ data.shipper }}</td>
              <td>{{ data.consignee }}</td>
              <td>{{ data.origin }}</td>
              <td>{{ data.destination }}</td>
              <td>{{ data.cargoType }}</td>
              <td>{{ data.pieces }}</td>
              <td>{{ data.weight }}</td>
              <td>{{ data.chargeableWeight }}</td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ShipmentList",

  data(){
    return {
      shipments: [
        {
          id: 1,
          date: '19/11/2020',
          fileNumber: '101-2020-00100',
          shipmentType: 'Airfreight Import',
          customer: 'Mustermann GmbH',
          shipper: 'Zhung Trading',
          consignee: 'Mustermann GmbH',
          origin: 'Shanghai (PVG)',
          destination: 'Munich (MUC)',
          cargoType: 'LCL',
          pieces: '5',
          weight: '3450.0',
          chargeableWeight: '3450.0',

        },
        {
          id: 1,
          date: '18/11/2020',
          fileNumber: '101-2020-00099',
          shipmentType: 'Airfreight Import',
          customer: 'Mustermann GmbH',
          shipper: 'Cemex USA',
          consignee: 'Mustermann GmbH',
          origin: 'Shanghai (PVG)',
          destination: 'Duesseldorf (DUS)',
          cargoType: 'LCL',
          pieces: '5',
          weight: '18450.0',
          chargeableWeight: '18450.0',

        }
      ]
    }
  }
}
</script>

<style scoped>

</style>