<template>
  <div>

    <div class="home">
      <img alt="Vue logo" src="../assets/logo.png" class="logo">
    </div>

    <div>

    </div>

  </div>
</template>

<script>
export default {
  name: "ShippingOrder"
}
</script>

<style scoped>

</style>