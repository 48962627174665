<template>
  <div>
    <b-card header="Test" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>

        <div class="row">
          <div class="col col-9">

            <div class="form-group">
              <label for="companyName">Company Name</label>
              <input type="text" id="companyName" class="form-control" :value="customer.company_name">
            </div>
            <div class="form-group">
              <label for="companyAddress">Address</label>
              <input type="text" id="companyAddress" class="form-control" >
            </div>
            <div class="form-group">
              <label for="companyAddress2">Address 2</label>
              <input type="text" id="companyAddress2" class="form-control" placeholder="Apartment, studio, or floor" >
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputState">Country</label>
                <select id="inputState" class="form-control">
                  <option selected>Choose...</option>
                  <option value="DE">Germany</option>
                  <option value="US">United States</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="inputCity">City</label>
                <input type="text" class="form-control" id="inputCity" required >
              </div>
              <div class="form-group col-md-2">
                <label for="inputZip">Zip</label>
                <input type="text" class="form-control" id="inputZip" required >
              </div>
            </div>

            <hr>

            <div class="btn-group float-right">
              <button class="btn btn-outline-secondary float-right mr-1">Edit</button>
            </div>



          </div>
          <div class="col col-3"></div>
        </div>


      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import {API_BASE_URL} from "@/config";

export default {
  name: "CustomersSingle",
  props: ['customerId'],

  data() {
    return {
      customer: [
        {

        }
      ],
      isLoading: false
    }
  },
  async created() {

    //axios.defaults.headers.common['Authorization'] = 'Bearer'

    try {

      const response = await axios.get(API_BASE_URL + '/customers/' + this.customerId)
      this.customer = response.data.customer

    } catch (e) {

      this.errors = e.response.data.errors
      this.isLoading = false

    }
  }


}
</script>

<style scoped>

</style>