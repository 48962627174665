<template>
  <div>
    <b-container fluid style="padding-top: 10px">

      <b-navbar type="dark" variant="info">
        <b-navbar-brand :to="{ name: 'employee-dashboard' }">myCargoApp - Employee</b-navbar-brand>
        <b-navbar-nav>

          <!-- Navbar Home -->
          <b-nav-item :to="{ name: 'employee-dashboard' }">Dashboard</b-nav-item>

          <!-- Navbar Customers -->
          <b-nav-item-dropdown text="Customers">
            <b-dropdown-item :to="{ name: 'employee-customers' }">All Customers</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'employee-customers-create' }">Create New Customer</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'employee-customers-blacklist' }">Blacklist</b-dropdown-item>
          </b-nav-item-dropdown>



        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <!-- Navbar Users -->
          <b-nav-item-dropdown :text="fullName" right>
            <b-dropdown-item :to="{ name: 'customer-user-profile' }">My Profile</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'Logout' }">Logout</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-navbar>
    </b-container>

    <b-container style="padding-top: 10px" fluid>

      <b-row>
        <b-col sm="12">
          <slot></slot>
        </b-col>
      </b-row>

    </b-container>

    <b-container style="padding-top: 10px" fluid>

      <b-row>
        <b-col sm="12">
          2021 - myCargoApp - v1.0
        </b-col>
      </b-row>

    </b-container>


  </div>
</template>

<script>
export default {
  name: "LayoutDashboardEmployee",

  data(){
    return {
      fullName: this.$store.getters.fullName
    }
  }
}
</script>

<style scoped>

</style>