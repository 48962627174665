<template>
  <div>
    <b-card header="Profile" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>

        <div class="row">
          <div class="col col-3">
            <div class="row justify-content-center mt-4 mb-3">
              <b-avatar size="250px" class="align-content-center" variant="secondary"></b-avatar>
            </div>
          </div>
          <div class="col col-9">

            <div class="alert alert-success" v-if="successMessage === true">
              Your profile has been updated successfully.
            </div>

            <div class="form-group">
              <label for="Company">Company</label>
              <input type="text" id="Company" class="form-control" disabled>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="">First Name</label>
                <input type="text" class="form-control" v-model="entry.first_name">
              </div>
              <div class="form-group col-md-6">
                <label for="">Last Name</label>
                <input type="text" class="form-control" >
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="">Phone Number</label>
                <input type="text" class="form-control" >
              </div>
              <div class="form-group col-md-4">
                <label for="">Fax Number</label>
                <input type="text" class="form-control">
              </div>
              <div class="form-group col-md-4">
                <label for="">Mobile Number</label>
                <input type="text" class="form-control">
              </div>
            </div>

            <div class="form-group">
              <label for="">E-Mail</label>
              <input type="text" class="form-control" disabled>
            </div>

            <div class="form-group">
              <label for="">Password</label>
              <input type="password" class="form-control" value="123456789" disabled>
            </div>

            <div class="btn-group float-right">
              <button class="btn btn-outline-secondary float-right mr-1">Save Profile</button>
              <b-button v-b-modal.changePassword>Password Reset</b-button>
            </div>

          </div>
        </div>

      </b-card-text>
    </b-card>

    <!-- Password Reset Modal Start -->
    <b-modal id="changePassword" title="Change Password">

      <div class="col">

          <div class="row w-75 mb-3">
            <label for="currentPassword">Current Password:</label>
            <input type="password" id="currentPassword" class="form-control">
          </div>
          <div class="row w-75 mb-3">
            <label for="currentPassword">New Password:</label>
            <input type="password" id="currentPassword" class="form-control">
          </div>
          <div class="row w-75">
            <label for="currentPassword">New Password (Confirmation):</label>
            <input type="password" id="currentPassword" class="form-control">
          </div>

      </div>

    </b-modal>
    <!-- Password Reset Modal End -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "UserProfile",

  data(){
    return {
      successMessage: false,
    }
  },
  computed: {
    ...mapGetters('UsersAuth', ['entry'])
  }


}
</script>

<style scoped>

</style>