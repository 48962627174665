<template>
  <div>
  <b-container fluid style="padding-top: 10px">

    <b-navbar type="dark" variant="dark">
      <b-navbar-brand :to="{ name: 'customer-dashboard' }">myCargoApp - Customer</b-navbar-brand>
      <b-navbar-nav>
        <!-- Navbar Home -->
        <b-nav-item :to="{ name: 'customer-dashboard' }">Dashboard</b-nav-item>

        <!-- Navbar ePricing -->
        <b-nav-item-dropdown text="ePricing" class="fas fa-cubes">
          <b-dropdown-item :to="{ name: 'customer-quotation-request' }">Request new Quote</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'customer-quotation-list' }">My Quotations</b-dropdown-item>
        </b-nav-item-dropdown>

        <!-- Navbar Customers -->
        <b-nav-item-dropdown text="eShipments">
          <b-dropdown-item :to="{ name: 'customer-shipments-create' }">Create new Shipment</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'customer-shipments-list' }">My Shipments</b-dropdown-item>
        </b-nav-item-dropdown>

        <!-- Navbar Pricing -->
        <b-nav-item-dropdown text="Accounting">
          <b-dropdown-item :to="{ name: 'customer-accounting-invoices' }">My Invoices</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'customer-accounting-statement' }">Statement</b-dropdown-item>
        </b-nav-item-dropdown>

        <!-- Navbar Settings -->
        <b-nav-item-dropdown text="Settings">
          <b-dropdown-item :to="{ name: 'customer-settings-company-profile' }">Company Profile</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'customer-settings-user-management' }">User Management</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <!-- Navbar Users -->
        <b-nav-item-dropdown text="" right>
          <b-dropdown-item :to="{ name: 'customer-user-profile' }">My Profile</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'Logout' }">Logout</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-navbar>
  </b-container>

  <b-container style="padding-top: 10px" fluid>

    <b-row>
      <b-col sm="12">
        <slot></slot>
      </b-col>
    </b-row>

  </b-container>

    <b-container fluid style="padding-top: 10px">

      <b-navbar type="dark" variant="dark">
        <b-navbar-brand to="/">myCargoApp v1.0</b-navbar-brand>
      </b-navbar>
    </b-container>


  </div>
</template>

<script>

export default {
  name: "LayoutDashboard",

  computed: {

  }
}

</script>

<style >

</style>