import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import numeral from 'numeral'

// Loading Layouts
import LayoutDashboard from './_layouts/LayoutDashboard'
import LayoutDashboardAdmin from "@/_layouts/LayoutDashboardAdmin";
import LayoutDashboardEmployee from "@/_layouts/LayoutDashboardEmployee";
import LayoutDefault from './_layouts/LayoutDefault'

import VueFormWizard from 'vue-form-wizard'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);

library.add(faUserSecret)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueFormWizard)
Vue.use(numeral)

Vue.component('default-layout', LayoutDefault)
Vue.component('dashboard-layout', LayoutDashboard)
Vue.component('admin-layout', LayoutDashboardAdmin)
Vue.component('employee-layout', LayoutDashboardEmployee)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')
