import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
axios.defaults.baseURL = 'http://mycargoapp.test/api'

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('access_token') || null,
        role: localStorage.getItem('user_role') || null,
        user: {}
    },
    getters: {
        loggedIn(state) {
            return state.token !== null
        },
        isAdmin(state) {

            if(state.role === 1){
                return true
            } else {
                return false
            }
        },
        hasAccess() {
            return localStorage.getItem('access_dashboard') || null
        },
        fullName() {
            return localStorage.getItem('full_name')
        },
        user_role() {
            return localStorage.getItem('user_role') || null
        },
        company_name() {
            return localStorage.getItem('company_name') || null
        }
    },
    mutations: {
        retrieveToken(state, token) {
            state.token = token
        },
        setUserRole(state, role) {
            state.role = role
        },
        destroyToken(state) {
            state.token = null
            state.role = null
        },
        setUserDetails(state, user) {
            state.user = user
        }
    },
    actions: {

        destroyToken(context){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token

            if(context.getters.loggedIn) {
                return new Promise((resolve, reject) => {

                    axios.post('/logout')
                        .then(response => {
                            localStorage.removeItem('access_token')
                            localStorage.removeItem('user_role')
                            localStorage.removeItem('access_dashboard')
                            localStorage.removeItem('full_name')
                            localStorage.removeItem('company_name')
                            context.commit('destroyToken')
                            resolve(response)
                        })
                        .catch(error => {
                            localStorage.removeItem('access_token')
                            localStorage.removeItem('user_role')
                            localStorage.removeItem('access_dashboard')
                            localStorage.removeItem('full_name')
                            localStorage.removeItem('company_name')
                            context.commit('destroyToken')
                            reject(error)
                        })
                })
            }
        },
        retrieveToken(context, credentials){

            return new Promise((resolve, reject) => {

                axios.post('/login', {
                    email: credentials.username,
                    password: credentials.password,
                })
                    .then(response => {
                        const token = response.data.access_token
                        const role = response.data.user.role_id
                        const user = response.data.user
                        const access_dashboard = response.data.user.access_dashboard
                        const full_name = response.data.user.first_name + ' ' + response.data.user.last_name
                        //const company_name = response.data.user.customer.company_name
                        localStorage.setItem('access_token', token)
                        localStorage.setItem('user_role', role)
                        localStorage.setItem('access_dashboard', access_dashboard)
                        localStorage.setItem('full_name', full_name)
                        //localStorage.setItem('company_name', company_name)
                        context.commit('retrieveToken', token)
                        context.commit('setUserRole', role)
                        context.commit('setUserDetails', user)
                        console.log(response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        }
    },
    modules: {},
    strict: debug
})