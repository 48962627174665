<template>
  <div>

    <div class="row mb-3">
      <div class="col-8">
        <b-card header="Shipments" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
      <div class="col-4">
        <b-card header="Operation Tasks" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <div class="col-8">
        <b-card header="Pricing" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
      <div class="col-4">
        <b-card header="Pricing Tasks" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <div class="col-8">
        <b-card header="Accounting" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
      <div class="col-4">
        <b-card header="Accounting Tasks" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <div class="col-8">
        <b-card header="Sales" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
      <div class="col-4">
        <b-card header="Sales Tasks" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <div class="col-8">
        <b-card header="Pricing" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
      <div class="col-4">
        <b-card header="Pricing Tasks" align="left"  header-text-variant="white" header-bg-variant="info" style="height: 210px">
          <b-card-text>



          </b-card-text>

        </b-card>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <div class="col-12">
        <b-card header="myCargoApp Promotion" align="left"  header-text-variant="white" header-bg-variant="info">
          <b-card-text>

            here comes the news

          </b-card-text>
        </b-card>
      </div>
    </div>

    <hr>



  </div>
</template>

<script>
export default {
name: "EmployeeDashboard"
}
</script>

<style scoped>

</style>