<template>
<div>
  <b-card header="Company Profile" align="left"  header-text-variant="white" header-bg-variant="secondary">
    <b-card-text>


      <div class="row">
        <div class="col col-3"></div>
        <div class="col col-9">

          <div class="alert alert-danger" role="alert" v-if="userIsAdmin === false">
            You are not allowed to make any changes here. Please contact your Admin.
          </div>

          <div class="form-group">
            <label for="companyName">Company Name</label>
            <input type="text" id="companyName" class="form-control" v-model="companyName" :disabled="userIsAdmin === false">
          </div>
          <div class="form-group">
            <label for="companyAddress">Address</label>
            <input type="text" id="companyAddress" class="form-control" v-model="companyAddress1" :disabled="userIsAdmin === false">
          </div>
          <div class="form-group">
            <label for="companyAddress2">Address 2</label>
            <input type="text" id="companyAddress2" class="form-control" placeholder="Apartment, studio, or floor" v-model="companyAddress2" :disabled="userIsAdmin === false">
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputState">Country</label>
              <select id="inputState" class="form-control" :disabled="userIsAdmin === false" v-model="companyCountry">
                <option selected>Choose...</option>
                <option value="DE">Germany</option>
                <option value="US">United States</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="inputCity">City</label>
              <input type="text" class="form-control" id="inputCity" required :disabled="userIsAdmin === false" v-model="companyCity">
            </div>
            <div class="form-group col-md-2">
              <label for="inputZip">Zip</label>
              <input type="text" class="form-control" id="inputZip" required :disabled="userIsAdmin === false" v-model="companyZipCode">
            </div>
          </div>

          <div class="btn-group float-right">
            <button class="btn btn-outline-secondary float-right mr-1" :disabled="userIsAdmin === false">Edit</button>
          </div>

        </div>
      </div>

    </b-card-text>
  </b-card>
</div>
</template>

<script>
export default {
  name: "CompanyProfile",

  data(){
    return {
      userIsAdmin: false,

      companyName: 'Mustermann GmbH',
      companyAddress1: 'Musterstrasse 1',
      companyAddress2: '',
      companyCountry: 'DE',
      companyCity: 'Moerfelden-Walldorf',
      companyZipCode: '64546'
    }
  },
}
</script>

<style scoped>

</style>