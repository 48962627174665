import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Register from "../components/auth/Register";
import CustomerDashboard from "../views/_customer/CustomerDashboard";
import Logout from "../components/auth/Logout";
import QuotationList from "../views/_customer/pricing/QuotationList";
import QuotationRequest from "../views/_customer/pricing/QuotationRequest";
import CompanyProfile from "../views/_customer/settings/CompanyProfile";
import UserManagement from "../views/_customer/settings/UserManagement";
import InvoicesList from "../views/_customer/accounting/InvoicesList";
import StatementOfAccount from "../views/_customer/accounting/StatementOfAccount";
import UserProfile from "../views/_customer/user/UserProfile";
import ShipmentList from "../views/_customer/shipments/ShipmentList";
import ShipmentCreate from "../views/_customer/shipments/ShipmentCreate";
import LoyaltyProgram from "../views/_customer/LoyaltyProgram";
import AdminDashboard from "@/views/_admin/AdminDashboard";
import EmployeeDashboard from "@/views/_employee/EmployeeDashboard";
import CustomersIndex from "@/views/_employee/customers/CustomersIndex";
import ShippingOrder from "@/views/ShippingOrder";
import Tracking from "@/views/Tracking";
import CustomersSingle from "@/views/_employee/customers/CustomersSingle";
import CustomersCreate from "@/views/_employee/customers/CustomersCreate";
import CustomersBlacklist from "@/views/_employee/customers/CustomersBlacklist";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: "default" },
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: "default", forwardIfAuth: true },
    component: Home
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/register',
    name: 'Register',
    meta: { layout: "default", forwardIfAuth: true },
    component: Register
  },
  {
    path: '/shipping-order',
    name: 'shipping-order',
    meta: {
      layout: "default",
    },
    component: ShippingOrder
  },
  {
    path: '/tracking',
    name: 'tracking',
    meta: {
      layout: "default",
    },
    component: Tracking
  },
  {
    path: '/admin',
    name: 'admin-dashboard',
    meta: {
      layout: "admin",
      requiresAuth: true,
    },
    component: AdminDashboard,

  },

  {
    path: '/dashboard',
    name: 'customer-dashboard',
    meta: {
      layout: "dashboard",
      requiresAuth: true
    },
    component: CustomerDashboard,

    children: [
      {
        path: '/pricing',
        name: 'customer-quotation-list',
        meta: {
          layout: "dashboard",
          requiresAuth: true
        },
        component: QuotationList,

        children: [
          {
            path: '/request',
            name: 'customer-quotation-request',
            meta: {
              layout: "dashboard",
              requiresAuth: true
            },
            component: QuotationRequest
          }
        ]
      },
      {
        path: '/shipments',
        name: 'customer-shipments-list',
        meta: {
          layout: "dashboard",
          requiresAuth: true
        },
        component: ShipmentList,

        children: [
          {
            path: '/create',
            name: 'customer-shipments-create',
            meta: {
              layout: "dashboard",
              requiresAuth: true
            },
            component: ShipmentCreate
          }
        ]
      }
    ]
  },

  {
    path: '/employee',
    name: 'employee-dashboard',
    meta: {
      layout: "employee",
      requiresAuth: true
    },
    component: EmployeeDashboard,
  },
  {
    path: '/employee/customers',
    name: 'employee-customers',
    meta: {
      layout: "employee",
      requiresAuth: true,
    },
    component: CustomersIndex,
  },
  {
    path: '/employee/customers/create',
    name: 'employee-customers-create',
    meta: {
      layout: "employee",
      requiresAuth: true,
    },
    component: CustomersCreate,
  },
  {
    path: '/employee/customers/:customerId',
    name: 'employee-customers-single',
    meta: {
      layout: "employee",
      requiresAuth: true,
    },
    props: true,
    component: CustomersSingle
  },
  {
    path: '/employee/customers/blacklist',
    name: 'employee-customers-blacklist',
    meta: {
      layout: "employee",
      requiresAuth: true,
    },
    component: CustomersBlacklist,
  },
  {
    path: '/accounting/invoices',
    name: 'customer-accounting-invoices',
    meta: { layout: "dashboard", requiresAuth: true },
    component: InvoicesList
  },
  {
    path: '/accounting/statement',
    name: 'customer-accounting-statement',
    meta: { layout: "dashboard", requiresAuth: true },
    component: StatementOfAccount
  },
  {
    path: '/settings/company-profile',
    name: 'customer-settings-company-profile',
    meta: { layout: "dashboard", requiresAuth: true },
    component: CompanyProfile
  },
  {
    path: '/settings/user-management',
    name: 'customer-settings-user-management',
    meta: { layout: "dashboard", requiresAuth: true },
    component: UserManagement
  },
  {
    path: '/user/profile',
    name: 'customer-user-profile',
    meta: { layout: "dashboard", requiresAuth: true },
    component: UserProfile
  },
  {
    path: '/loyalty-program',
    name: 'customer-loyalty-program',
    meta: { layout: "dashboard", requiresAuth: true },
    component: LoyaltyProgram
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(localStorage.getItem('access_token') === null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }

  else if(to.matched.some(record => record.meta.forwardIfAuth)){
    if(localStorage.getItem('access_token') !== null) {

      if(localStorage.getItem('user_role') == 1) {
        next({ path: '/admin' })
      }
      else if(localStorage.getItem('user_role') == 2 || localStorage.getItem('user_role') == 3) {
        next({ name: 'employee-dashboard' })
      }
      else {
        next({ path: '/dashboard' })
      }

    } else {
      next()
    }
  }

  else {
    next()
  }
})

export default router
