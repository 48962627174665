<template>
<div>
  <div>
    <b-card header="Create new Customer" align="left"  header-text-variant="white" header-bg-variant="info">
      <b-card-text>

        <div class="row">
          <div class="col col-9">

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Matchcode">Match Code</label>
                <input type="text" id="Matchcode" class="form-control" v-model="customer.match_code">
              </div>

            </div>
            <div class="form-group">
              <label for="companyName">Company Name</label>
              <input type="text" id="companyName" class="form-control" v-model="customer.company_name">
            </div>
            <div class="form-group">
              <label for="companyAddress">Address</label>
              <input type="text" id="companyAddress" class="form-control" v-model="customer.address1">
            </div>
            <div class="form-group">
              <label for="companyAddress2">Address 2</label>
              <input type="text" id="companyAddress2" class="form-control" placeholder="" v-model="customer.address2">
            </div>
            <div class="form-group">
              <label for="street">Street</label>
              <input type="text" id="street" class="form-control" placeholder="" v-model="customer.street">
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputState">Country</label>
                <select id="inputState" class="form-control" v-model="customer.country_id">
                  <option selected>Choose...</option>
                  <option value="DE">Germany</option>
                  <option value="US">United States</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="inputCity">City</label>
                <input type="text" class="form-control" id="inputCity" v-model="customer.city">
              </div>
              <div class="form-group col-md-2">
                <label for="inputZip">Zip</label>
                <input type="text" class="form-control" id="inputZip" v-model="customer.zip_code">
              </div>
            </div>

            <hr>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputPhoneNumber">Phone Number</label>
                <input type="text" class="form-control" id="inputPhoneNumber" v-model="customer.phone">
              </div>
              <div class="form-group col-md-4">
                <label for="inputFaxNumber">Fax Number</label>
                <input type="text" class="form-control" id="inputFaxNumber" v-model="customer.fax">
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail">E-Mail</label>
                <input type="text" class="form-control" id="inputEmail" v-model="customer.email">
              </div>
            </div>

            <hr>



            <div class="btn-group float-right">
              <button class="btn btn-outline-info float-right mr-1" @click="saveCustomer">Create</button>
            </div>



          </div>
          <div class="col col-3"></div>
        </div>


      </b-card-text>
    </b-card>
  </div>
</div>
</template>

<script>
import { API_BASE_URL } from '@/config'
import axios from "axios";

export default {
  name: "CustomersCreate",

  data() {
    return {
      customer: {
        match_code: '',
        company_name: '',
        address1: '',
        address2: '',
        street: '',
        zip_code: '',
        city: '',
        state: '',
        country_id: '',
        phone: '',
        fax: '',
        email: '',
      },
      countries: {},
      submitted: false,
    }
  },
  methods: {

    saveCustomer() {
      const dataToAdd = {
        match_code: this.customer.match_code,
        company_name: this.customer.company_name,
        address1: this.customer.address1,
        address2: this.customer.address2,
        street: this.customer.street,
        zip_code: this.customer.zip_code,
        city: this.customer.city,
        state: this.customer.state,
        country_id: this.customer.country_id,
        phone: this.customer.phone,
        fax: this.customer.fax,
        email: this.customer.email,
      };

      axios.post(API_BASE_URL + '/customers/', dataToAdd)
          .then(response => {
            console.log(response)
            this.submitted = true

            //router.push('employee-customers')
          })
          .catch(e => {
            console.log(e)
            this.submitted = false
          })
    },
    getCountries() {

    }

  }
}
</script>

<style scoped>

</style>