<template>
  <div>

    <h2 class="">Good day, Username</h2>

    <div class="row mb-3">
      <div class="col-4">
        <b-card header="Shipments" align="left"  header-text-variant="white" header-bg-variant="secondary" style="height: 210px">
          <b-card-text>

            <ul>
              <li><b>35</b> total Shipments</li>
              <li><b>35</b> handled Shipments</li>
              <li><b>35</b> pending Shipments</li>
            </ul>

          </b-card-text>
          <template #footer>
            <router-link :to="{ name: 'customer-shipments-create' }"><button class="form-control form-control-sm btn-secondary">Book a Shipment</button></router-link>
          </template>

        </b-card>
      </div>
      <div class="col-4">
        <b-card header="Quotes" align="left"  header-text-variant="white" header-bg-variant="secondary" style="height: 210px">
          <b-card-text>

            <ul>
              <li><b>35</b> total Quotes</li>
              <li><b>35</b> bookable Quotes</li>
              <li><b>35</b> pending Quotes</li>
            </ul>

          </b-card-text>
          <template #footer>
            <router-link :to="{ name: 'customer-quotation-request' }"><button class="form-control form-control-sm btn-secondary">Request a Quote</button></router-link>
          </template>

        </b-card>
      </div>
      <div class="col-4">
        <b-card header="Loyalty Program" align="left"  header-text-variant="white" header-bg-variant="secondary" style="height: 210px">
          <b-card-text>

            <ul>
              <li><b>35</b> Points</li>
            </ul>

          </b-card-text>
          <template #footer>
            <router-link :to="{ name: 'customer-loyalty-program' }"><button class="form-control form-control-sm btn-secondary">Go to Royalty Program</button></router-link>
          </template>

        </b-card>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <b-card header="myCargoApp Promotion" align="left"  header-text-variant="white" header-bg-variant="secondary">
          <b-card-text>

            here comes the news

          </b-card-text>
        </b-card>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CustomerDashboard"
}
</script>

<style scoped>

</style>