<template>
  <div>

      <form action="#" @submit.prevent="login">

        <div v-if="serverErrors" class="server-error w-25 m-auto">{{ serverErrors }}</div>

        <div class="mb-3 mt-3">
          <validation-provider rules="required" v-slot="e">
            <label for="username">E-Mail: </label>
            <input type="text" name="username" id="username" class="form-control login-input" placeholder="Your enter your E-Mail" v-model="username"
            :class="{ 'border-danger' : e.errors[0] }">
          </validation-provider>
        </div>
        <div class="mb-3">
          <validation-provider rules="required" v-slot="p">
            <label for="password">Password: </label>
            <input type="password" name="password" id="password" class="form-control login-input" placeholder="Your enter your Password" v-model="password"
                   :class="{ 'border-danger' : p.errors[0] }">
          </validation-provider>
        </div>
        <div class="mb-3">
          <button type="submit" class="btn btn-secondary w-25">Login</button>
        </div>
        <span>Do you want to register? Please click <router-link :to="{ name: 'Register' }">here</router-link>.</span>
      </form>

  </div>
</template>

<script>
import {extend, ValidationProvider} from 'vee-validate';

extend('required', value => {
  return value >= 0;
});

export default {
  name: "Login",

  components: {
    ValidationProvider
  },

  data() {
    return {
      username: '',
      password: '',
      serverErrors: '',
    }
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    }
  },

  methods: {
    login() {

      this.$store.dispatch('retrieveToken', {
        username: this.username,
        password: this.password,
      })
          .then(() => {

            if(this.isAdmin === true)
            {
              this.$router.push(
                  {
                    name: 'admin-dashboard'
                  }
              )
            }
            else
            {
              this.$router.push(
                  {
                    name: 'Home'
                  }
              )
            }

            //this.$router.push({ name: 'Home' })
          })
          .catch(error => {
            this.serverErrors = error.response.data.message
            this.password = ''
          })

    },

  }
}
</script>

<style scoped>

.login-input {
  width: 25%;
  margin: 5px auto;
}

.server-error {
  width: 25%;
  margin-bottom: 12px;
  font-size: 16px;
  padding: 10px 16px;
  color: #a94442;
  background: #F3DEDE;
  border-radius: 4px;
}

</style>