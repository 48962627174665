<template>
  <div>
    <b-card header="Customers" align="left"  header-text-variant="white" header-bg-variant="info">
      <b-card-text>
        
        <div class="float-left" style="padding-bottom: 10px">
          <input type="text" class="form-control" v-model="searchQuery" placeholder="Search...">
        </div>

        <div class="float-right" style="padding-bottom: 10px">
          <router-link :to="{ name: 'employee-customers-create' }"><button class="btn btn-info">Create new Customer</button></router-link>
        </div>

        <div style="padding-top: 10px">
          <table class="table table-responsive-sm table-striped border">
            <thead>
            <tr>
              <th scope="col">Matchcode</th>
              <th scope="col">Company Name</th>
              <th scope="col">City</th>
              <th scope="col">Country</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in resultSearchQuery" :key="item.id" @dblclick="goToDetails(item.id)">

              <td>{{ item.match_code }}</td>
              <td>{{ item.company_name }} <span v-if="item.blocked === '1'" class="badge rounded-pill bg-danger text-white">Blocked</span></td>
              <td>{{ item.city }}</td>
              <td>{{ item.country_id }}</td>
              <td>
                <router-link :to="{ name: 'employee-customers-single', params: { customerId: 1 } }"><button class="btn btn-info btn-sm">Details</button></router-link>
              </td>

            </tr>
            </tbody>
          </table>
        </div>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { API_BASE_URL } from '../../../config'
import axios from "axios";

export default {
  name: "CustomersIndex",

  data() {
    return {
      searchQuery: null,
      customers: [],
      isLoading: true,
      errors: '',
    }
  },
  async created() {

    //axios.defaults.headers.common['Authorization'] = 'Bearer'

    try {

      const response = await axios.get(API_BASE_URL + '/customers')
      this.customers = response.data.customers

    } catch (e) {

      this.errors = e.response.data.errors
      this.isLoading = false

    }
  },
  computed: {
    resultSearchQuery(){

      if(this.searchQuery !== null){
        const value = this.searchQuery.charAt(0).toUpperCase() + this.searchQuery.slice(1);

        return this.customers.filter(function (item){
          return  item.match_code.indexOf(value) > -1 ||
                  item.company_name.indexOf(value) > -1 ||
                  item.city.indexOf(value) > -1 ||
                  item.country_id.indexOf(value) > -1
        })
      } else {
        return this.customers
      }
    }
  },
  methods: {
    goToDetails(id) {
      this.$router.push({ name: 'employee-customers-single', params: { customerId: id } });
    }
  }
}
</script>

<style scoped>

</style>