<template>
<div>

  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" class="logo">
  </div>

</div>
</template>

<script>
export default {
  name: "Tracking"
}
</script>

<style scoped>

</style>