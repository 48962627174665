<template>
  <div>
    <b-card header="Statement of Account" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>
        <div class="float-right" style="padding-bottom: 10px">

        </div>

        <div style="padding-top: 10px">
          <table class="table table-responsive-sm table-striped border">
            <thead>
            <tr>
              <th scope="col">Month</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(statement, index) in statementOfAccounts" :key="index">
              <td>{{ statement.month }}</td>
              <td>{{ statement.totalAmount }} {{ statement.totalAmountCurrency }}</td>
              <td>{{ statement.statementStatus }}</td>
              <td>Download</td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "StatementOfAccount",

  data(){
    return {

      statementOfAccounts: [
        {
          id: 1,
          month: '01/2020',
          totalAmount: '4500.00',
          totalAmountCurrency: 'EUR',
          statementStatus: 'Paid'
        },
        {
          id: 2,
          month: '12/2019',
          totalAmount: '454500.00',
          totalAmountCurrency: 'EUR',
          statementStatus: 'Paid'
        }
      ]

    }
  }

}
</script>

<style scoped>

</style>