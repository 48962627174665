<template>
<div>

  <div class="container">
    <h2>Application Form</h2>
    <div class="row mb-5">
      <div class="col">
        Please fill out our application form to register. After checking you will get access to our cargotool.
      </div>
    </div>

    <hr>

    <h5>Company Account Information</h5>

    <div class="form-group">
      <label for="companyName">Company Name (*)</label>
      <input type="text" class="form-control" id="companyName" placeholder="Your company name" required v-model="companyName">
    </div>
    <div class="form-group">
      <label for="inputAddress">Address (*)</label>
      <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" required v-model="companyAddress">
    </div>
    <div class="form-group">
      <label for="inputAddress2">Address 2</label>
      <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" v-model="companyAddress2">
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="inputState">Country (*)</label>
        <select id="inputState" class="form-control" v-model="companyCountry">
          <option selected>Choose...</option>
          <option value="DE">Germany</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="inputCity">City (*)</label>
        <input type="text" class="form-control" id="inputCity" required v-model="companyCity">
      </div>
      <div class="form-group col-md-2">
        <label for="inputZip">Zip (*)</label>
        <input type="text" class="form-control" id="inputZip" required v-model="companyZip">
      </div>
    </div>

    <hr>

    <h5>User Account Information</h5>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">First Name (*)</label>
        <input type="email" class="form-control" id="inputEmail4">
      </div>
      <div class="form-group col-md-6">
        <label for="inputPassword4">Last Name (*)</label>
        <input type="password" class="form-control" id="inputPassword4">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputEmail4">Email (*)</label>
        <input type="email" class="form-control" id="inputEmail4">
      </div>
      <div class="form-group col-md-6">
        <label for="inputPassword4">Password (*)</label>
        <input type="password" class="form-control" id="inputPassword4">
      </div>
    </div>

    <hr>

    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="gridCheck">
        <label class="form-check-label" for="gridCheck">
          I have read and accept the Terms & Conditions.
        </label>
      </div>
    </div>
    <button type="submit" class="btn btn-primary">Sign in</button>
  </div>

</div>
</template>

<script>
export default {
  name: "Register",

  data() {
    return {

      companyName: '',
      companyAddress: '',
      companyAddress2: '',
      companyCountry: '',
      companyCity: '',
      companyZip: '',

      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userPassword: ''
    }
  }
}
</script>

<style scoped>

.container{
  width: 50%;
}

</style>