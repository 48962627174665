<template>
<div>
  Hallo
</div>
</template>

<script>

export default {
name: "AdminDashboard",

  computed: {
  userData() {
    return this.$store.state.user
  }
  }

}
</script>

<style scoped>

</style>