<template>
  <div>
    <b-card header="User Management" align="left"  header-text-variant="white" header-bg-variant="secondary">
      <b-card-text>

        <div class="float-right" style="padding-bottom: 10px">
          <button class="btn btn-secondary" :disabled="userIsAdmin === false">Add new User</button>
        </div>

        <div style="padding-top: 10px">
          <table class="table table-responsive-sm table-striped border">
            <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">eMail</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Role</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in users" :key="index">
              <td>{{ data.firstName }} {{ data.lastName }} ({{ data.gender }})</td>
              <td>{{ data.eMail }}</td>
              <td>{{ data.phoneNumber }}</td>
              <td>{{ data.userRole }}</td>
              <td>

                <button class="btn btn-secondary btn-sm mr-1" :disabled="userIsAdmin === false">Edit</button>
                <button class="btn btn-secondary btn-sm" :disabled="userIsAdmin === false">Delete</button>

              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "UserManagement",

  data(){
    return {
      userIsAdmin: false,

      users: [
        {
          id: 1,
          firstName: 'Patrick',
          lastName: 'Horvath',
          gender: 'Mr.',
          eMail: 'admin@admin.com',
          phoneNumber: '+49 6105 999999',
          userRole: 'Admin'
        },
        {
          id: 2,
          firstName: 'Test',
          lastName: 'Horvath',
          gender: 'Mr.',
          eMail: 'user@user.com',
          phoneNumber: '+49 6105 999998',
          userRole: 'User'
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>